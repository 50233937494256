export const socialIcon = [
  {
    title: "Follow on Facebook",
    icon: "/img/common/Facebook.svg",
  },
  {
    title: "Contact us with Messenger",
    icon: "/img/common/Messenger.svg",
  },
  {
    title: "Contact us with Zalo",
    icon: "/img/common/Zalo.svg",
  },
];
