export const listMenu = [
  { name: "COMBO SIÊU HỜI", id: "65ef1fdb8403e0a023006d29" },
  { name: "TINH DẦU", id: "65a738d6abe37efb1dc73e61" },
  { name: "POD 1 LẦN", id: "65a738cbabe37efb1dc73e5f" },
  {
    name: "POD KIT THÂN MÁY",
    id: "65a738c4abe37efb1dc73e5d",
    // submenu: [{ name: "TINH DẦU FREEBASE" }, { name: "TINH DẦU SALT NIC" }],
  },
  { name: "ĐẦU POD- OCC", id: "65a738baabe37efb1dc73e5b" },
  { name: "TIN TỨC" },
];

export const listSidebar = [
  { name: "COMBO SIÊU HỜI", id: "65ef1fdb8403e0a023006d29" },
  { name: "TINH DẦU", id: "65a738d6abe37efb1dc73e61" },
  { name: "POD 1 LẦN", id: "65a738cbabe37efb1dc73e5f" },
  {
    name: "POD KIT THÂN MÁY",
    id: "65a738c4abe37efb1dc73e5d",
    // submenu: [{ name: "TINH DẦU FREEBASE" }, { name: "TINH DẦU SALT NIC" }],
  },
  { name: "ĐẦU POD- OCC", id: "65a738baabe37efb1dc73e5b" },
];
